import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    delay: { type: Number, default: 2000 },
    dataType: { type: String, default: "script" },
  };

  connect() {
    this.startPolling();
  }

  disconnect() {
    this.stopPolling();
  }

  startPolling() {
    this.timer = setInterval(() => {
      if (this.dataTypeValue === "script") {
        this.fetchWithRailsAjax();
      } else if (this.dataTypeValue === "html") {
        this.fetchWithTurboFetch();
      }
    }, this.delayValue);
  }

  stopPolling() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  fetchWithRailsAjax() {
    Rails.ajax({
      url: this.urlValue,
      type: "get",
      dataType: "script",
      success: (response) => {
        console.log("success");
      },
      error: (error) => console.error("Ajax error:", error),
    });
  }

  fetchWithTurboFetch() {
    fetch(this.urlValue, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        return response;
      })
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        Turbo.renderStreamMessage(text);
      })
      .catch((error) => {
        console.error("Polling fetch error:", error);
      });
  }
}
