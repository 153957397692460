import { Controller } from "@hotwired/stimulus";

// Sets input
export default class extends Controller {
  static values = { submitUrl: String };
  static targets = ["count", "checkbox", "show", "hide"];

  updateCount() {
    const checked = this.checkboxTargets.filter((checkbox) => checkbox.checked);
    this.countTarget.textContent = checked.length;

    if (checked.length === 0) {
      this.showTarget.classList.add("hidden");
      this.hideTarget.classList.remove("hidden");
    } else {
      this.hideTarget.classList.add("hidden");
      this.showTarget.classList.remove("hidden");
    }
  }

  async submitSelections(event) {
    const submitUrl = event.params.submitUrl;
    let params = new URLSearchParams();
    this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .forEach((checkbox) => {
        params.append(checkbox.name, checkbox.value);
      });

    try {
      const response = await fetch(`${submitUrl}?${params.toString()}`, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const html = await response.text();
      Turbo.renderStreamMessage(html);
    } catch (error) {
      console.error("Submission error:", error);
    }
  }
}
