import React from "react";

import { ExerciseScreenProps } from "./Elements";

const ThermometerContext = React.createContext(undefined);

function ThermometerProvider(props: ExerciseScreenProps) {
  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    careLink,
    libraryLink,
    completedExercise,
    readOnly,
  } = props;
  const defaultState = {
    value: 5,
    thoughts: "",
  };

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [thermometerValue, setThermometerValue] = React.useState(
    initialState.value
  );
  const [thoughts, setThoughts] = React.useState<string>(initialState.thoughts);

  const value = {
    thermometerValue,
    setThermometerValue,
    assignedExerciseId,
    patientFirstName,
    thoughts,
    setThoughts,
    chatLink,
    careLink,
    libraryLink,
    readOnly,
  };

  return <ThermometerContext.Provider value={value} {...props} />;
}

function useThermometer() {
  const context = React.useContext(ThermometerContext);
  if (!context) {
    throw new Error("useThermometer must be used within a ThermometerProvider");
  }
  return context;
}
const ExerciseFormContext = React.createContext(undefined);

function ExerciseFormProvider(props: ExerciseScreenProps) {
  const defaultState = {
    preview: false,
    readOnly: false,
  };

  const initialState = { ...defaultState, ...props };

  const [preview] = React.useState(initialState.preview);
  const [readOnly] = React.useState(initialState.readOnly);

  const value = {
    careLink: props.careLink,
    careLinkText: props.careLinkText,
    libraryLink: props.libraryLink,
    preview,
    readOnly,
  };

  return <ExerciseFormContext.Provider value={value} {...props} />;
}

function useExerciseForm() {
  const context = React.useContext(ExerciseFormContext);
  if (!context) {
    throw new Error(
      "useExerciseForm must be used within a ExerciseFormProvider"
    );
  }
  return context;
}

export {
  ThermometerProvider,
  ThermometerContext,
  useThermometer,
  ExerciseFormProvider,
  ExerciseFormContext,
  useExerciseForm,
};
